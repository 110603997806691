import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEqual } from 'lodash';
import * as VehiclesState from '../../store/recoil/vehicles';
import VehicleService from '../../Services/VehicleService';
import * as OnlinePanelState from '../OnlinePanel/OnlinePanelState';
import { TCheckboxState } from './interfaces';
import * as VehiclesDateState from '../../store/recoil/vehiclesData';
import NavigatorService from './NavigatorService';
import { IVehicleStatuses, IVehiclesData, TVehicleStatus } from '../Vehicles/interfaces';
import { autofocusAtom, vehicleFocusAtom } from '../OnlineMapNew/OnlineMapState';

interface INavigatorCustomerProps {
  id: number;
  name: string;
}

export default function CustomerNew(props: INavigatorCustomerProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const vehicles = useRecoilValue(VehiclesState.vehicles);
  const [selectedVehicles, setSelectedVehicles] = useRecoilState<number[]>(
    VehiclesState.selectedVehicles
  );
  const setVehicleFocus = useSetRecoilState<boolean>(vehicleFocusAtom);
  const setAutofocus = useSetRecoilState<boolean>(autofocusAtom);
  const isOnlinePanelOpen = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const [checkboxState, setCheckboxState] = React.useState<TCheckboxState>('unChecked');
  const selectedVehicleStatus = useRecoilValue<string>(VehiclesState.selectedVehicleStatus);
  const vehiclesData = useRecoilValue<IVehiclesData>(VehiclesDateState.vehiclesData);

  const handleToggleCollapseVehicles = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    const collapseElement = document.getElementById(`customer-vehicles-${props.id}`);

    if (collapseElement) {
      collapseElement.classList.toggle('in');
      if (collapseElement.classList.contains('in')) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    }
  };

  const handleCustomersChecked = (): boolean => {
    let countVehiclesByCustomerId = 0;
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === props.id) {
        countVehiclesByCustomerId += 1;
      }
    });

    let countSelectedVehiclesByCustomerId = 0;
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === props.id && selectedVehicles.includes(vehicle.id)) {
        countSelectedVehiclesByCustomerId += 1;
      }
    });

    if (countVehiclesByCustomerId === countSelectedVehiclesByCustomerId) {
      // input.indeterminate = false;
      // input.checked = true;
      return true;
    }
    if (
      countSelectedVehiclesByCustomerId > 0 &&
      countSelectedVehiclesByCustomerId < countVehiclesByCustomerId
    ) {
      return false;
    }
    if (
      countVehiclesByCustomerId > countSelectedVehiclesByCustomerId &&
      countSelectedVehiclesByCustomerId > 0
    ) {
      // input.indeterminate = true;
      // input.checked = false;
      return false;
    }
    return false;
  };

  const handleToggleSelectAllCustomerVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (!isOnlinePanelOpen) {
      setVehicleFocus(false);
      setAutofocus(true);
    }

    if (!handleCustomersChecked()) {
      const tempSelectedVehicles = NavigatorService.setShowAllVehicles(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicleStatus as TVehicleStatus,
        props.id
      ).selectedVehicles;

      if (checkboxState === 'semiChecked') {
        setSelectedVehicles((state: number[]) =>
          VehicleService.unselectAllVehiclesByCustomerId(state, vehicles, props.id)
        );
        return;
      }

      if (isEqual(tempSelectedVehicles, selectedVehicles)) {
        setSelectedVehicles((state: number[]) =>
          VehicleService.unselectAllVehiclesByCustomerId(state, vehicles, props.id)
        );
      } else {
        setSelectedVehicles((oldSelectedVehicles) => {
          if (oldSelectedVehicles) {
            const newSelectedVehicles = NavigatorService.setShowAllVehicles(
              vehicles,
              vehiclesData as IVehicleStatuses,
              selectedVehicleStatus as TVehicleStatus,
              props.id
            ).selectedVehicles;
            const filteredVehicles = Object.keys(vehicles)
              .filter(
                (item) =>
                  vehicles[Number(item)].customerId !== props.id &&
                  oldSelectedVehicles.includes(Number(item))
              )
              .map((item) => vehicles[Number(item)].id);

            const joinedSelectedVehicles = [...filteredVehicles, ...newSelectedVehicles];
            return [...new Set(joinedSelectedVehicles)];
          }
          return NavigatorService.setShowAllVehicles(
            vehicles,
            vehiclesData as IVehicleStatuses,
            selectedVehicleStatus as TVehicleStatus,
            props.id
          ).selectedVehicles;
        });
      }
    } else {
      // Set selected vehicles to recoil
      setSelectedVehicles((state: number[]) =>
        VehicleService.unselectAllVehiclesByCustomerId(state, vehicles, props.id)
      );
      // Set selected vehicles to recoil
      // setSelectedVehicles((state: number[]) =>
      //   VehicleService.selectAllVehiclesByCustomerId(state, vehicles, props.id)
      // );
    }
  };

  const handleCheckboxRef = (input: HTMLInputElement | null) => {
    if (input) {
      let countVehiclesByCustomerId = 0;
      Object.keys(vehicles).forEach((vehicleId: string) => {
        const vehicle = vehicles[Number(vehicleId)];
        if (vehicle && vehicle.customerId === props.id) {
          countVehiclesByCustomerId += 1;
        }
      });

      let countSelectedVehiclesByCustomerId = 0;
      Object.keys(vehicles).forEach((vehicleId: string) => {
        const vehicle = vehicles[Number(vehicleId)];
        if (vehicle && vehicle.customerId === props.id && selectedVehicles.includes(vehicle.id)) {
          countSelectedVehiclesByCustomerId += 1;
        }
      });

      if (countVehiclesByCustomerId === countSelectedVehiclesByCustomerId) {
        input.indeterminate = false;
        input.checked = true;
        setCheckboxState('checked');
      } else if (
        countVehiclesByCustomerId > countSelectedVehiclesByCustomerId &&
        countSelectedVehiclesByCustomerId > 0
      ) {
        input.indeterminate = true;
        input.checked = false;
        setCheckboxState('semiChecked');
      } else {
        input.indeterminate = false;
        input.checked = false;
        setCheckboxState('unChecked');
      }
    }
  };

  return (
    <div className="b-navigator-actions">
      <div className="b-navigator-actions-group">
        <div
          className="e-checkbox"
          id={`customer-${props.id}`}
          onClick={handleToggleSelectAllCustomerVehicles}
          data-cy={`navigator-customer-${props.id}`}
        >
          <label>
            <input
              type="checkbox"
              ref={handleCheckboxRef}
              // checked={isChecked}
              // onChange={handleToggleSelectAllCustomerVehicles}
              className="js-checkbox-indeterminate"
              id="navigatorVehicleCheckboxIndeterminate"
              data-cy={`navigator-customer-${props.id}-input-${checkboxState}`}
            />
            <span>
              <strong>{props.name}</strong>
            </span>
          </label>
        </div>
        <a
          href="#"
          title=""
          className={`cmd-icons cmd-icon-${
            isCollapsed ? 'dropdown' : 'dropup'
          } b-navigator-actions-group-toggler`}
          onClick={handleToggleCollapseVehicles}
          data-customer-id={props.id}
        >
          {/* {test} */}
        </a>
      </div>
    </div>
    // <div className="car-item bg-black">
    //   <div className="navigator-checkbox car-name-checkbox py-3">
    //     <button
    //       type="button"
    //       className=""
    //       id={`customer-${props.id}`}
    //       onClick={handleToggleSelectAllCustomerVehicles}
    //       data-cy={`navigator-customer-${props.id}`}
    //     >
    //       <span className={checkboxClass} />
    //     </button>
    //   </div>
    //   <div
    //     className="ci-information py-3 mb-0"
    //     data-toggle="collapse"
    //     data-target={`#collapse-${props.id}`}
    //     aria-expanded="true"
    //     aria-controls={`collapse-${props.id}`}
    //   >
    //     <span onClick={handleToggleCollapseVehicles} data-customer-id={props.id}>
    //       <i
    //         className={`cmd-icons ${
    //           isCollapsed
    //             ? 'cmd-icon-expander-triangle-right-small'
    //             : 'cmd-icon-expander-triangle-down-small'
    //         } mr-1 position-relative bottom-1 right-3 text-gray-light`}
    //       />
    //       <strong className="fs-2 text-gray-light">{props.name}</strong>
    //     </span>
    //   </div>
    // </div>
  );
}
