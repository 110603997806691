import { atom, RecoilState, selector, selectorFamily } from 'recoil';
import localforage from 'localforage';
import localForageEffect from './effects/localForageEffect';
import setSelectedVehiclesToSocketServerEffect from './effects/setSelectedVehiclesToSocketServerEffect';
import * as VehiclesDataState from './vehiclesData';
import { IVehicleOnlinePanels } from '../../components/OnlinePanel/OnlinePanelState';
import * as OnlinePanelState from '../../components/OnlinePanel/OnlinePanelState';
import * as CustomersState from './customers';
import { boundsAtom, isClusterEnabledAtom, zoomAtom } from '../../components/OnlineMapNew/OnlineMapState';
import { MIN_POINTS_FOR_DISABLED_CLUSTER } from '../../components/OnlineMapNew/GoogleMapLayer/constants';
import Config from '../../../config';
import UserService from '../../Services/UserService';
import { IOption } from '../../components/Forms/ReactHookFormFields/Calendar';
import {
  ILastRideDistanceCumulative,
  IVehicle,
  IVehicleGdprDataAllowed,
  IVehicleGroup,
  IVehicles,
  IVehiclesWithData,
  IVehicleWithData,
  VehiclesBasicInfo,
} from '../../components/Vehicles/interfaces';
import { SuperclusterWorker } from '../../components/OnlineMapNew/CreateWorkers';

export const getSelectedVehiclesFromIndexedDb = async (): Promise<number[]> => {
  const selectedVehicles = await localforage.getItem<number[]>('selectedVehicles');
  if (!selectedVehicles) {
    return [];
  }
  return selectedVehicles;
};

// State for storing info about vehicles
// This state is upated from socket events, so it changes frequently.s
// It should be used only when we need to get realtime data about vehicles
export const vehicles = atom<IVehicles>({
  key: 'vehicles',
  default: {},
});

/**
 * State for storing basic info about vehicles (id, name, licenceNumber, vehicleTypeId, customerId)
 * This state is updated only when vehicles are loaded. So it can be used everywhere
 * when we need to get basic info about vehicles wihtout triggering changes
 */
export const vehiclesBasicInfo = atom<VehiclesBasicInfo>({
  key: 'vehiclesBasicInfo',
  default: {},
});

// Used for options in the vehicle select
export const vehiclesOptions = atom<IOption[]>({
  key: 'vehiclesOptions',
  default: [],
});

export const vehicleGroups = atom<IVehicleGroup[]>({
  key: 'vehicleGroups',
  default: [],
});

export const vehicleGroupsBySelectedCustomers = selector<IVehicleGroup[]>({
  key: 'vehicleGroupsBySelectedCustomers',
  get: ({ get }) => {
    const selectedCustomers = get(CustomersState.selectedCustomers);
    const vehicleGroupsList = get(vehicleGroups);
    return vehicleGroupsList.filter((vehicleGroup: IVehicleGroup) =>
      selectedCustomers.includes(vehicleGroup.customerId)
    );
  },
});

export const selectedVehicles = atom<number[]>({
  key: 'selectedVehicles',
  // default: getSelectedVehiclesFromIndexedDb(),
  default: [],
  effects: [localForageEffect('selectedVehicles'), setSelectedVehiclesToSocketServerEffect()],
});

export const isVehicleGdprDataAllowed = atom<IVehicleGdprDataAllowed>({
  key: 'isVehicleGdprDataAllowed',
  default: {},
});

export const selectedVehiclesWithData = selector<IVehiclesWithData>({
  key: 'selectedVehiclesWithData',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const selectedVehiclesList = get(selectedVehicles);
    const isClusterEnabledValue = get<boolean>(isClusterEnabledAtom);
    const isVehicleGdprDataAllowedData = get(isVehicleGdprDataAllowed);
    const vehiclesList = get(vehicles);
    const vehiclesDataList = get(VehiclesDataState.vehiclesData);
    const onlinePanels = get<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
    const vehiclesWithDataList: IVehiclesWithData = {};
    const zoomData = get(zoomAtom);
    const boundsData = get(boundsAtom);
    selectedVehiclesList.forEach((vehicleId: number) => {
      const vehicle = vehiclesList[vehicleId];
      const vehicleData = vehiclesDataList[vehicleId];
      const onlinePanel = onlinePanels[vehicleId];

      if (
        vehicle &&
        vehicleData &&
        vehicleData.position &&
        vehicleData.position.latitude &&
        vehicleData.position.longitude
      ) {
        const newVehicleWithData: IVehicleWithData = {
          id: vehicle.id,
          name: vehicle.name,
          licenceNumber: vehicle.licenceNumber,
          vehicleTypeId: vehicle.vehicleTypeId,
          vehicleGroups: vehicle.vehicleGroups,
          customerId: vehicle.customerId,
          driver: vehicle.driver,
          showInNavigator: vehicle.showInNavigator,
          showInNavigatorGroups: vehicle.showInNavigatorGroups,
          status: vehicleData.status,
          position: vehicleData.position,
          // isPrivate: vehicleData.isPrivate,
          drivingMode: vehicleData.drivingMode,
          lastRide: vehicleData.lastRide,
          onlinePanel,
        };
        vehiclesWithDataList[vehicleId] = newVehicleWithData;
      }
    });
    const userId = UserService.getUserId();
    if (userId) {
      SuperclusterWorker.postMessage({
        vehiclesData: vehiclesWithDataList,
        bounds: boundsData,
        zoom: zoomData,
        minPoints: isClusterEnabledValue
          ? Config.onlineMap.superCluster.minPoints
          : MIN_POINTS_FOR_DISABLED_CLUSTER,
        isVehicleGdprDataAllowed: isVehicleGdprDataAllowedData,
        userId,
      });
    }
    return vehiclesWithDataList;
  },
});

// Vehicles/VehiclesState.vehicle()
export const vehicleWithData = selectorFamily<IVehicleWithData | null, number | false>({
  key: 'vehicleWithData',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return null;
      }
      const vehiclesList = get(vehicles);
      const vehiclesDataList = get(VehiclesDataState.vehiclesData);
      const onlinePanels = get<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
      const vehicle = vehiclesList[vehicleId];
      const vehicleData = vehiclesDataList[vehicleId];
      const onlinePanel = onlinePanels[vehicleId];

      if (!vehicle) {
        console.warn(`vehicleWithData: vehicle ${vehicleId} not found.`);
        return null;
      }

      const withData: IVehicleWithData = {
        id: vehicle.id,
        name: vehicle.name,
        licenceNumber: vehicle.licenceNumber,
        vehicleTypeId: vehicle.vehicleTypeId,
        vehicleGroups: vehicle.vehicleGroups,
        customerId: vehicle.customerId,
        driver: vehicle.driver,
        showInNavigator: vehicle.showInNavigator,
        showInNavigatorGroups: vehicle.showInNavigatorGroups,
        status: vehicleData.status,
        position: vehicleData.position,
        // isPrivate: vehicleData.isPrivate,
        drivingMode: vehicleData.drivingMode,
        lastRide: vehicleData.lastRide,
        onlinePanel,
      };
      return withData;
    },
});

export const vehicleById = selectorFamily<IVehicle | null, number | false>({
  key: 'vehicleById',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return null;
      }
      const vehiclesList = get(vehicles);
      return vehiclesList[vehicleId];
    },
});

export const vehicle = (id: number): RecoilState<IVehicle | null> =>
  atom<IVehicle | null>({
    key: `vehicle_${id}`,
    default: null,
  });

export const selectedVehicleStatus = atom<string>({
  key: 'status',
  default: '',
});

export const lastRideDistanceCumulative = atom<ILastRideDistanceCumulative | null>({
  key: 'lastRideDistanceCumulative',
  default: null,
});
